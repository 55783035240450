<script>
import Vue from 'vue';
import ApplicantTermsOfServiceStateEnum from '~/enums/ApplicantTermsOfServiceStateEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: ApplicantTermsOfServiceStateEnum.all(),
  }),
});
</script>
