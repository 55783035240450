<template>
  <t-filter-wrapper :label="$t('fields.organization')">
    <OrganizationSelect
      :value="value"
      multiple
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </t-filter-wrapper>
</template>

<script>
import OrganizationSelect from '~/components/ResourceSelect/OrganizationSelect';

export default {
  components: {
    OrganizationSelect,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
