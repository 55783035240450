<template>
  <t-filter-wrapper :label="$t('fields.terms_of_service_state')">
    <applicant-terms-of-service-state-select
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </t-filter-wrapper>
</template>

<script>
import ApplicantTermsOfServiceStateSelect from '~/components/EnumSelect/ApplicantTermsOfServiceStateSelect';

export default {
  components: {
    ApplicantTermsOfServiceStateSelect,
  },

  props: {
    value: {
      type: [Boolean, Array],
      required: false,
      default: () => [],
    },
  },
};
</script>
