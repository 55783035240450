<template>
  <t-filter-wrapper :label="$t('fields.usage_type')">
    <usage-type-select
      :value="value"
      multiple
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </t-filter-wrapper>
</template>

<script>
import UsageTypeSelect from '~/components/EnumSelect/UsageTypeSelect';

export default {
  components: {
    UsageTypeSelect,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
