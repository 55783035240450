class UserVerificationStateEnum {
  static VERIFIED = true;
  static UNVERIFIED = false;

  static labelKey(item: boolean) {
    return UserVerificationStateEnum.meta(item)?.labelKey;
  }

  static tagType(item: boolean) {
    return UserVerificationStateEnum.meta(item)?.tagType;
  }

  static meta(item: boolean) {
    switch (item) {
      case UserVerificationStateEnum.VERIFIED:
        return {
          labelKey: 'global.statuses.verified',
          tagType: 'success',
        };
      case UserVerificationStateEnum.UNVERIFIED:
        return {
          labelKey: 'global.statuses.unverified',
          tagType: 'warning',
        };
    }
  }

  static all() {
    return [
      {
        key: UserVerificationStateEnum.VERIFIED,
        labelKey: UserVerificationStateEnum.labelKey(UserVerificationStateEnum.VERIFIED),
      },
      {
        key: UserVerificationStateEnum.UNVERIFIED,
        labelKey: UserVerificationStateEnum.labelKey(UserVerificationStateEnum.UNVERIFIED),
      },
    ];
  }
}

export default UserVerificationStateEnum;
