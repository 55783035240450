<template>
  <t-filter-wrapper :label="$t('fields.user_verification_state')">
    <user-verification-state-select
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </t-filter-wrapper>
</template>

<script>
import UserVerificationStateSelect from '~/components/EnumSelect/UserVerificationStateSelect';

export default {
  components: {
    UserVerificationStateSelect,
  },

  props: {
    value: {
      type: [Boolean, Array],
      required: false,
      default: () => [],
    },
  },
};
</script>
