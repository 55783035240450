<template>
  <t-filter-wrapper :label="$t('fields.state')">
    <invitation-state-select
      :value="value"
      multiple
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
  </t-filter-wrapper>
</template>

<script>
import InvitationStateSelect from '~/components/EnumSelect/InvitationStateSelect';

export default {
  components: {
    InvitationStateSelect,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
