class ApplicantTermsOfServiceStateEnum {
  static ACCEPTED = true;
  static PENDING = false;

  static labelKey(item: boolean) {
    return ApplicantTermsOfServiceStateEnum.meta(item)?.labelKey;
  }

  static tagType(item: boolean) {
    return ApplicantTermsOfServiceStateEnum.meta(item)?.tagType;
  }

  static meta(item: boolean) {
    switch (item) {
      case ApplicantTermsOfServiceStateEnum.ACCEPTED:
        return {
          labelKey: 'global.statuses.accepted',
          tagType: 'success',
        };
      case ApplicantTermsOfServiceStateEnum.PENDING:
        return {
          labelKey: 'global.statuses.pending',
          tagType: 'warning',
        };
    }
  }

  static all() {
    return [
      {
        key: ApplicantTermsOfServiceStateEnum.ACCEPTED,
        labelKey: ApplicantTermsOfServiceStateEnum.labelKey(
          ApplicantTermsOfServiceStateEnum.ACCEPTED,
        ),
      },
      {
        key: ApplicantTermsOfServiceStateEnum.PENDING,
        labelKey: ApplicantTermsOfServiceStateEnum.labelKey(
          ApplicantTermsOfServiceStateEnum.PENDING,
        ),
      },
    ];
  }
}

export default ApplicantTermsOfServiceStateEnum;
