<script>
import Vue from 'vue';
import UserVerificationStateEnum from '~/enums/UserVerificationStateEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: UserVerificationStateEnum.all(),
  }),
});
</script>
