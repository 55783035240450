<script>
import Vue from 'vue';
import InvitationStateEnum from '~/enums/InvitationStateEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: InvitationStateEnum.all(),
  }),
});
</script>
